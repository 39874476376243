
import { TicketTypes, UserTicket } from "@/store/modules/ticket/ticket.types";
import { DefaultState } from "@/types/types";
import { Component, Mixins } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { dashWithTime } from "@/filters/utils";
import { UtilsMixin } from "@/mixins/utils.mixin";

const ticketX = namespace("Ticket");

@Component({
  filters: {
    dashWithTime,
  },
})
export default class ViewTicket extends Mixins(UtilsMixin) {
  @ticketX.State(TicketTypes.TICKET_STATUSES)
  public ticketStatuses!: string[];

  @ticketX.State(TicketTypes.VIEW_TICKET_STATE)
  public viewTicket!: DefaultState;

  @ticketX.State(TicketTypes.TICKET_DATA)
  public userTicket!: UserTicket;

  @ticketX.Mutation(TicketTypes.SET_VIEW_TICKET_DIALOG)
  public setViewTicket!: (viewTicket: boolean) => void;

  public updateTicketStep = 1;

  public isValid = false;

  closeViewTicketDialog(): void {
    this.setViewTicket(false);
  }
}
